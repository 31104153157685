<script setup lang="ts">
import { useNotificationStore } from '~/stores/alert/useNotificationStore';
import Alert from '~/components/shared/Base/Alert.vue';

const store = useNotificationStore();
const isVisible= ref(store.messages.length > 0);

const messageLength = computed(() => store.messages.length);

watch(messageLength, () => {
    if(store.messages.length === 0) {
        isVisible.value = false;
    } else {
        isVisible.value = true;
    }
});

const onClose = (id: string) : void => {
    isVisible.value = false;
    store.removeNotification(id);
}
</script>

<template>
    <v-snackbar
        v-model="isVisible" 
        autoHeight
        location="bottom right"
        :timeout="-1"
        :vertical="true">
        <Alert
            v-for="notification in store.messages"
            :key="notification.id"
            :type="notification.type"
            :closable="true"
            @close="onClose">
            <template #title>
                {{ notification.title }}
            </template>
            <template #text>
                {{ notification.text }}
            </template>
        </Alert>
    </v-snackbar>
</template>

<style scoped lang="scss">
    :deep(.v-snackbar__wrapper){
        background-color: transparent;
        box-shadow: none;

        .v-snackbar__content {
            display: flex;
            flex-direction: column;
            gap: var(--Padding-pa-2, 8px);
            justify-content: flex-end;
            align-items: flex-end;
            
            padding: 0;
            border-radius: 0;
        }

        /** Чтобы работала custom иконка */
        .mdi:before,
        .mdi:after {
            font: inherit;
            font-size: 20px;
        }
    }
</style>